import React, { useRef } from "react";
import { Editor as TinyMceEditor } from "@tinymce/tinymce-react";
import { uploadImage } from "api/request.api";

function Editor(props) {
    return (
        <div className="mb-3">
            <label htmlFor={props.id} className="form-label fw-semibold">
                {props.label}
            </label>
            <TinyMceEditor
                apiKey="your-api-key"
                onInit={(evt, editor) => (props.editorRef.current = editor)}
                initialValue={props.initialValue}
                disabled={props.readOnly}
                init={{
                    height: 300,
                    menubar: true,
                    plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "code",
                        "wordcount",
                    ],
                    images_upload_handler: (blobInfo) => {
                        return new Promise((resolve, reject) => {
                            const formData = new FormData();
                            formData.append(
                                "image",
                                blobInfo.blob(),
                                blobInfo.filename()
                            );

                            fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/upload`, {
                                method: "POST",
                                body: formData,
                            })
                                .then((response) => response.json())
                                .then((data) => {
                                    const imageUrl = data.data.path; // Extract the image URL from the response
                                    resolve(imageUrl);
                                })
                                .catch((error) => {
                                    reject(error);
                                });
                        });
                    },

                    toolbar:
                        "undo redo | blocks | " +
                        "bold italic forecolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                    content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
            />
        </div>
    );
}

export default Editor;
